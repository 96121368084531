/* You can add global styles to this file, and also import other style files */
@import "bootstrap-icons/font/bootstrap-icons.css";

@font-face {
  font-family: poppins-black;
  src: local("poppins-black"),
    url(./assets/fonts/Poppins-Black.ttf) format("truetype");
}
@font-face {
  font-family: poppins-bold;
  src: local("poppins-bold"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: poppins-regular;
  src: local("poppins-regular"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: poppins-light;
  src: local("poppins-light"),
    url(./assets/fonts/Poppins-Light.ttf) format("truetype");
}
@font-face {
  font-family: roboto-bold;
  src: local("roboto-bold"),
    url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: roboto-regular;
  src: local("roboto-regular"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: roboto-light;
  src: local("roboto-light"),
    url(./assets/fonts/Roboto-Light.ttf) format("truetype");
}

:root {
  --cm-blue: rgb(0, 102, 179);
  --cm-dark-blue: rgb(27, 37, 47);
  --cm-gold: rgb(151, 142, 99);
  --cm-white: rgb(255, 255, 255);
  --customer-orange: rgb(247, 120, 0);
  --customer-dark-orange: rgb(37, 18, 0);
  --customer-black: rgb(35, 35, 35);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
}

body {
  font-family: roboto-regular, Arial, Helvetica, sans-serif;
}

.poppins-black {
  font-family: poppins-black;
}
.poppins-bold {
  font-family: poppins-bold;
}
.poppins-regular {
  font-family: poppins-regular;
}
.poppins-light {
  font-family: poppins-light;
}
.roboto-bold {
  font-family: roboto-bold;
}
.roboto-regular {
  font-family: roboto-regular;
}
.roboto-light {
  font-family: roboto-light;
}

.text-cmBlue {
  color: var(--cm-blue);
}
.text-cmDarkBlue {
  color: var(--cm-dark-blue);
}
.text-cmGold {
  color: var(--cm-gold);
}

.text-customerOrange {
  color: var(--customer-orange);
}
.text-customerDarkOrange {
  color: var(--customer-dark-orange);
}
.text-customerBlack {
  color: var(--customer-black);
}

.cursor-pointer {
  cursor: pointer;
}
